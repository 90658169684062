/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";

import TextInputBassic from '../subcomponents/textinputs/TextInputBassic'
import TextAreaInput from '../subcomponents/textinputs/TextAreaInput'
import DatePickerCust from '../subcomponents/textinputs/DatePicker'
import DropDown from '../subcomponents/textinputs/DropDown'
import Breadcrumb from '../subcomponents/headers/Breadcrumb'
import TitleForm from '../subcomponents/titles/TitleForm'

const AddPatients = () => {
  const [firstName, setFirstName] = useState('');
  const [middleName, setMiddleName] = useState('');
  const [lastName, setLastName] = useState('');
  const [typeId, setTypeId] = useState(null);
  const [noId, setNoId] = useState('');

  const [listId] = useState([{ value: 1, label: "KTP"}, { value: 2, label: "Passport"}])
  const [listGender] = useState([{ value: 1, label: "Laki-laki"}, { value: 2, label: "Perempuan"}])
  const [listReligion] = useState([{ 
    value: 1, label: "Islam"
  }, { 
    value: 2, label: "Kristen"
  }, { 
    value: 2, label: "Protestan"
  }, { 
    value: 2, label: "Hindu"
  }, { 
    value: 2, label: "Budha"
  }, { 
    value: 2, label: "Agama Kepercayaan"
  }])

  const [listStudy] = useState([{ 
    value: 1, label: "SD"
  }, { 
    value: 2, label: "SMP"
  }, { 
    value: 2, label: "SMA"
  }, { 
    value: 2, label: "S1"
  }, { 
    value: 2, label: "S2"
  }, { 
    value: 2, label: "S3"
  }, { 
    value: 2, label: "Lain-nya"
  }])

  const [listBloodType] = useState([{ 
    value: 1, label: "O"
  }, { 
    value: 2, label: "A"
  }, { 
    value: 2, label: "B"
  }, { 
    value: 2, label: "AB"
  }])

  const [listHistory] = useState([{ value: 1, label: "Yes"}, { value: 2, label: "No"}])

  const [listProvince] = useState([{ value: 1, label: "DKI Jakarta"}])
  const [listCity] = useState([{ value: 1, label: "Jakarta Timur"}])
  const [listSubdistrict] = useState([{ value: 1, label: "Makasar"}])
  const [listWard] = useState([{ value: 1, label: "Halim Perdana Kusuma"}])

  const [gender, setGender] = useState(null);
  const [dateOfBird, setDateOfBird] = useState('');

  const [religion, setReligion] = useState('');

  const [addressIdentity, setAddressIdentity] = useState('');
  const [provinceIdentity, setProvinceIdentity] = useState('');
  const [cityIdentity, setCityIdentity] = useState('');
  const [subdistrictIdentity, setSubdistrictIdentity] = useState('');
  const [wardIdentity, setWardIdentity] = useState('');

  const [addressDomicile, setAddressDomicile] = useState('');
  const [provinceDomicile, setProvinceDomicile] = useState('');
  const [cityDomicile, setCityDomicile] = useState('');
  const [subdistrictDomicile, setSubdistrictDomicile] = useState('');
  const [wardDomicile, setWardDomicile] = useState('');

  const [study, setStudy] = useState('');
  const [work, setWork] = useState('');
  const [citizenship, setCitizenship] = useState('');
  const [contactPersonName, setContactPersonName] = useState('');
  const [contactPersonNumber, setContactPersonNumber] = useState('');

  const [bloodType, setBloodType] = useState('');

  const [jantung, setJantung] = useState('');
  const [diabetes, setDiabetes] = useState('');
  const [hemophilia, setHemophilia] = useState('');
  const [hepatitis, setHepatitis] = useState('');
  const [gastring, setGastring] = useState('');
  const [otherDiseases, setOtherDiseases] = useState('');
  const [medicineAllergy, setMedicineAllergy] = useState('');

  const savePatient = () => {
    let objectSave = {
      
    }
  }

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-patient"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <Breadcrumb parent={'Pasien '} child={'Tambah Pasien'} />
            {/* /Page Header */}

            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Tambah Pasien</h4>
                  </div>

                  <div className="card-body">
                    <form>
                      <div className="row">
                        <div className="col-12" style={{ fontSize: '12px', fontWeight: 'bold', paddingBottom: '10px' }}>
                          <div className="form-heading">
                            <p>No Medical Record: <span className="login-danger" style={{ fontWeight: 'normal' }}> * No Medical Record akan di-generate otomatis setelah data disimpan</span></p>
                          </div>
                        </div>

                        <TitleForm title={ 'Identitas Pasien: ' } spesific={ '*' } />

                        <TextInputBassic 
                          label={'Nama Depan'} 
                          value={firstName} 
                          type={'text'} 
                          placeHolder={'Agus'} 
                          setValue={setFirstName} 
                          class={'col-12 col-md-6 col-xl-4'}/>

                        <TextInputBassic 
                          label={'Nama Tengah'} 
                          value={middleName} 
                          type={'text'} 
                          placeHolder={'Parjo'} 
                          setValue={setMiddleName} 
                          class={'col-12 col-md-6 col-xl-4'}/>

                        <TextInputBassic 
                          label={'Nama Belakang'} 
                          value={lastName} 
                          type={'text'} 
                          placeHolder={'Soeherman'} 
                          setValue={setLastName} 
                          class={'col-12 col-md-6 col-xl-4'}/>

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Jenis Kelamin'}
                          value={gender}
                          change={setGender}
                          dataSelect={listGender}/>

                        <DatePickerCust 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Tanggal lahir'}
                          value={dateOfBird}
                          change={setDateOfBird}/>

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Agama'}
                          value={religion}
                          change={setReligion}
                          dataSelect={listReligion}/>

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Tipe Identitas'}
                          value={typeId}
                          change={setTypeId}
                          dataSelect={listId}/>

                        <TextInputBassic 
                          label={'Nomor Identitas'} 
                          value={noId} 
                          type={'number'} 
                          placeHolder={'-'} 
                          setValue={setNoId} 
                          class={'col-12 col-md-6 col-xl-4'}/>
                        
                        <TitleForm title={ 'Informasi Tambahan Pasien: ' } spesific={ '*' } />

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Pendidikan'}
                          value={study}
                          change={setStudy}
                          dataSelect={listStudy}/>
                        <TextInputBassic 
                          label={'Kewarganegaraan'} 
                          value={citizenship} 
                          type={'text'} 
                          placeHolder={'Indonesia'} 
                          setValue={setCitizenship} 
                          class={'col-12 col-md-6 col-xl-4'}/>
                        <TextInputBassic 
                          label={'Pekerjaan'} 
                          value={work} 
                          type={'text'} 
                          placeHolder={'Pegawai Swasta'} 
                          setValue={setWork} 
                          class={'col-12 col-md-6 col-xl-4'}/>
                        <TextInputBassic 
                          label={'Pihak yg dapat dihubungi'} 
                          value={contactPersonName} 
                          type={'text'} 
                          placeHolder={'Marta'} 
                          setValue={setContactPersonName} 
                          class={'col-12 col-md-6 col-xl-4'}/>
                        <TextInputBassic 
                          label={'Nomor yg dapat dihubungi'} 
                          value={contactPersonNumber} 
                          type={'number'} 
                          placeHolder={'-'} 
                          setValue={setContactPersonNumber} 
                          class={'col-12 col-md-6 col-xl-4'}/>

                        <TitleForm title={ 'Alamat Sesuai Identitas: ' } spesific={ '*' } />

                        <TextAreaInput
                          label={'Alamat Sesuai Identitas'} 
                          value={addressIdentity}
                          placeHolder={'Jalan Cempaka ...'} 
                          setValue={setAddressIdentity} 
                          class={'col-12 col-md-6 col-xl-4'}
                        />

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Profinsi'}
                          value={provinceIdentity}
                          change={setProvinceIdentity}
                          dataSelect={listProvince}/>

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Kota'}
                          value={cityIdentity}
                          change={setCityIdentity}
                          dataSelect={listCity}/>

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Kecamatan'}
                          value={subdistrictIdentity}
                          change={setSubdistrictIdentity}
                          dataSelect={listSubdistrict}/>

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Kelurahan'}
                          value={wardIdentity}
                          change={setWardIdentity}
                          dataSelect={listWard}/>
                        
                        <TitleForm title={ 'Alamat Tempat Tinggal: ' } spesific={ '*' } />

                        <TextAreaInput
                          label={'Alamat Sesuai Identitas'} 
                          value={addressDomicile}
                          placeHolder={'Jalan Cempaka ...'} 
                          setValue={setAddressDomicile} 
                          class={'col-12 col-md-6 col-xl-4'}
                        />

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Profinsi'}
                          value={provinceDomicile}
                          change={setProvinceDomicile}
                          dataSelect={listProvince}/>

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Kota'}
                          value={cityDomicile}
                          change={setCityDomicile}
                          dataSelect={listCity}/>

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Kecamatan'}
                          value={subdistrictDomicile}
                          change={setSubdistrictDomicile}
                          dataSelect={listSubdistrict}/>

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Kelurahan'}
                          value={wardDomicile}
                          change={setWardDomicile}
                          dataSelect={listWard}/>

                        <TitleForm title={ 'Riwayat Medis: ' } spesific={ '*' } />

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Golongan Darah'}
                          value={bloodType}
                          change={setBloodType}
                          dataSelect={listHistory}/>

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Apakah Anda memiliki riwayat Jantung'}
                          value={jantung}
                          change={setJantung}
                          dataSelect={listHistory}/>

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Apakah Anda memiliki riwayat Diabetes'}
                          value={diabetes}
                          change={setDiabetes}
                          dataSelect={listHistory}/>

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Apakah Anda memiliki riwayat Hemophilia'}
                          value={hemophilia}
                          change={setHemophilia}
                          dataSelect={listHistory}/>

                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Apakah Anda memiliki riwayat Hepatitis'}
                          value={hepatitis}
                          change={setHepatitis}
                          dataSelect={listHistory}/>
                          
                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Apakah Anda memiliki riwayat Gastring'}
                          value={gastring}
                          change={setGastring}
                          dataSelect={listHistory}/>

                        <TextAreaInput
                          label={'Apakah Anda memiliki penyakit lainnya'} 
                          value={otherDiseases}
                          placeHolder={'Penyakit ...'} 
                          setValue={setOtherDiseases} 
                          class={'col-12 col-md-6 col-xl-4'}
                        />

                        <TextAreaInput
                          label={'Apakah Anda memiliki Alergi Obat'} 
                          value={medicineAllergy}
                          placeHolder={'Alergi Obat ...'} 
                          setValue={setMedicineAllergy} 
                          class={'col-12 col-md-6 col-xl-4'}
                        />

                      </div>
                    </form>
                  </div>

                  <div className="card-footer">
                    <div className="col-12">
                      <div className="doctor-submit text-end">
                        <button className="btn btn-primary" type="submit">Simpan</button>
                        <button className="btn btn-primary" style={{ background: 'transparent', border: 'solid 2px darkyellow', marginLeft: '20px' }} type="submit">Batalkan</button>
                        {/* <button
                          type="submit"
                          className="btn btn-primary submit-form me-2"
                        >
                          Submit
                        </button> */}
                        {/* <button type="button" className="btn btn-warning">Batalkan</button> */}
                        {/* <button
                          type="submit"
                          className="btn btn-primary cancel-form"
                        >
                          Cancel
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddPatients;
