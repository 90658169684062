/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toogleMenu } from '../infrastructure/store/actions';

import { Link } from "react-router-dom";
import { blog, dashboard, doctor, doctorschedule, logout, menuicon04, menuicon06, menuicon08, menuicon09, menuicon10, menuicon11, menuicon12, menuicon14, menuicon15, menuicon16, patients, sidemenu } from './imagepath';
import Scrollbars from "react-custom-scrollbars-2";


const Sidebar = (props) => {
  const dispatch = useDispatch();
  const toogleMenuStatus = useSelector(state => state.toogleMenu);

  const [sidebar, setSidebar] = useState("");
  const handleClick = (e, item, item1, item3) => {
    const div = document.querySelector(`#${item}`);
    const ulDiv = document.querySelector(`.${item1}`);
    e?.target?.className ? ulDiv.style.display = 'none' : ulDiv.style.display = 'block'
    e?.target?.className ? div.classList.remove('subdrop') : div.classList.add('subdrop');
  }

  useEffect(() => {
    if (props?.id && props?.id1) {
      const ele = document.getElementById(`${props?.id}`);
      handleClick(ele, props?.id, props?.id1);
    }
    console.log(JSON.stringify(toogleMenuStatus))

    if (toogleMenuStatus) {
      document.body.classList.remove("mini-sidebar")
    } else {
      document.body.classList.add("mini-sidebar")
    }
  }, [toogleMenuStatus])


  const expandMenu = () => {
    document.body.classList.remove("expand-menu");
  };
  const expandMenuOpen = () => {
    document.body.classList.add("expand-menu");
  };
  return (
    <>
      <div className="sidebar" id="sidebar">
        <Scrollbars
          autoHide
          autoHideTimeout={1000}
          autoHideDuration={200}
          autoHeight
          autoHeightMin={0}
          autoHeightMax="95vh"
          thumbMinSize={30}
          universal={false}
          hideTracksWhenNotNeeded={true}
        >
          <div className="sidebar-inner slimscroll">
            <div id="sidebar-menu" className="sidebar-menu"
              onMouseLeave={expandMenu}
              onMouseOver={expandMenuOpen}
            >
              <ul>
                {/* <li className="menu-title">Main</li> */}
                <li className="submenu" style={{ paddingTop: '20px' }}>
                  
                  <Link to="#" id="menu-item" onClick={(e) => {
                    handleClick(e, "menu-item", "menu-items")
                  }}>
                    <span className="menu-side">
                      <img src={dashboard} alt="" />
                    </span>{" "}
                    {
                      toogleMenuStatus ?
                      <><span> Dashboard </span> <span className="menu-arrow" /></> : null
                    }
                  </Link>
                  {
                    toogleMenuStatus ?
                    <ul style={{ display: sidebar === 'Dashboard' ? 'block' : "none" }} className='menu-items'>
                      <li>
                        <Link className={props?.activeClassName === 'admin-his-dashboard' ? 'active' : ''} to="/admin-his-dashboard">Admin His Dashboard</Link>
                      </li>
                    </ul> : 
                    <ul style={{ display: sidebar === 'Dashboard' ? 'block' : "none" }} className='menu-items'></ul>
                  }

                  
                </li>

                <li className="submenu">
                  <Link to="#" id="menu-item2" onClick={(e) => handleClick(e, "menu-item2", "menu-items2")}>
                    <span className="menu-side">
                      <img src={patients} alt="" />
                    </span>{" "}
                    {
                      toogleMenuStatus ?
                      <><span>Patients </span> <span className="menu-arrow" /></> : null
                    }
                  </Link>
                  {
                    toogleMenuStatus ?
                    <ul style={{ display: "none" }} className="menu-items2">
                      <li>
                        <Link className={props?.activeClassName === 'patient-list' ? 'active' : ''} to="/patientslist">Patients List</Link>
                      </li>
                      <li>
                        <Link className={props?.activeClassName === 'appointment-list' ? 'active' : ''} to="/appointmentlist">Appointment List</Link>
                      </li>
                      <li>
                        <Link className={props?.activeClassName === 'add-check-in' ? 'active' : ''} to="/addcheckin">Tambah Check In</Link>
                      </li>
                      <li>
                        <Link className={props?.activeClassName === 'add-patient' ? 'active' : ''} to="/addpatients">Add Patients</Link>
                      </li>
                    </ul> : 
                    <ul style={{ display: "none" }} className="menu-items2"></ul>
                  }
                  
                </li>
              </ul>
              <div className="logout-btn">
                <Link to="/login">
                  <span className="menu-side">
                    <img src={logout} alt="" />
                  </span>{" "}
                  {
                    toogleMenuStatus ?
                    <span>Logout</span> : null
                  }
                </Link>
              </div>
            </div>
          </div>
        </Scrollbars>
      </div>
    </>
  )
}
export default Sidebar
