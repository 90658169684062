import React from "react";

const TitleForm = (props) => {
  const data = props

  return (
    <>
      <div 
        className="col-12" 
        style={{ 
          fontSize: '12px', 
          fontWeight: 'bold', 
          paddingBottom: '10px' 
        }}>
        <div 
          className="form-heading" 
          style={{ 
            borderTop: 'solid 1px lightGrey', 
            height: '20px' 
          }}>
        </div>
        <p>
          { data.title }
          <span 
            className="login-danger" 
            style={{ fontWeight: 'normal' }}>
             { data.spesific }
          </span>
        </p>
      </div>
    </>
  )
}

export default TitleForm;
// col-12 col-md-6 col-xl-4