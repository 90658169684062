import React from "react";

const TextInputBassic = (props) => {
  const data = props

  return (
    <>
      <div className={data.class}>
        <div className="form-group local-forms" style={{ zIndex: '2' }}>
          <label>
            { data.label } <span className="login-danger">*</span>
          </label>
          <input
            value={ data.value }
            className="form-control"
            type={data.type}
            placeholder={ data.placeHolder }
            onChange={(e) => data.setValue(e.target.value)}
          />
        </div>
      </div>
    </>
  )
}

export default TextInputBassic;