import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { getUser } from '../../../infrastructure/store/actions';

import FeatherIcon from "feather-icons-react/build/FeatherIcon";
import Sidebar from "../../Sidebar";
import Header from "../../Header";
import {
  morning_img_01,
} from "../../imagepath";
import { Link } from "react-router-dom";

const Admin_His_Dashboard = () => {
  const [dummyVariable, setDummyVariable] = useState()
  const dispatch = useDispatch();
  const dataDummy = useSelector(state => state.dataDummy);

  useEffect(() => {
    setDummyVariable('Lorem Ipsum Dolor Sir Amet')
    dispatch(getUser());
  }, []);

  return (
    <>
      <Header />
      <Sidebar
        id="menu-item"
        id1="menu-items"
        activeClassName="admin-his-dashboard"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="#">Dashboard </Link>
                    </li>
                    <li className="breadcrumb-item">
                      <i className="feather-chevron-right">
                        <FeatherIcon icon="chevron-right" />
                      </i>
                    </li>
                    <li className="breadcrumb-item active">Admin Dashboard</li>
                  </ul>
                </div>
              </div>
            </div>
            {/* /Page Header */}
            <div className="good-morning-blk">
              <div className="row">
                <div className="col-md-6">
                  <div className="morning-user">
                    <h2>
                      Selamat Pagi, Admin <span>{dataDummy?.name}</span>
                    </h2>
                    <p>Semoga hari-mu menyenangkan,</p>
                    <p>{ dummyVariable }</p>
                  </div>
                </div>
                <div className="col-md-6 position-blk">
                  <div className="morning-img">
                    <img src={morning_img_01}
                     alt="#" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default Admin_His_Dashboard;
