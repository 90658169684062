/* eslint-disable no-unused-vars */
import React,{ useState }  from 'react';
import { Table } from "antd";
import Header from '../Header';
import Sidebar from '../Sidebar';
import {onShowSizeChange,itemRender}from  '../Pagination'
import { blogimg10, imagesend, pdficon, pdficon3, pdficon4, plusicon, refreshicon, searchnormal, blogimg12,
     blogimg2, blogimg4, blogimg6, blogimg8, icCheckIn} from '../imagepath';
import { Link } from 'react-router-dom';
import Breadcrumb from '../subcomponents/headers/Breadcrumb'
import { useNavigate } from "react-router-dom";

const AppointmentList = () => {
  const navigate = useNavigate();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const datasource = [
    {
      id:"1",
      noMedRecord: '001/001/001-001',
      namaPasien: 'Dhong Ngok',
      jenisRawat: 'Rawat Jalan',
      unit: 'Klinik Umum',
      dokter: 'Eko Broto',
      tanggalKedatangan: new Date(),
      waktuKedatangan: new Date(),
      status: true,
    }, {
      id:"2",
      noMedRecord: '001/001/001-001',
      namaPasien: 'Phon Thank',
      jenisRawat: 'Rawat Jalan',
      unit: 'Klinik Umum',
      dokter: 'Eko Broto',
      tanggalKedatangan: new Date(),
      waktuKedatangan: new Date(),
      status: true,
    }, {
      id:"3",
      noMedRecord: '001/001/001-001',
      namaPasien: 'Phan Think',
      jenisRawat: 'Rawat Jalan',
      unit: 'Klinik Umum',
      dokter: 'Eko Broto',
      tanggalKedatangan: new Date(),
      waktuKedatangan: new Date(),
      status: true,
    }, {
      id:"4",
      noMedRecord: '001/001/001-001',
      namaPasien: 'Mhw Wheck',
      jenisRawat: 'Rawat Jalan',
      unit: 'Klinik Umum',
      dokter: 'Eko Broto',
      tanggalKedatangan: new Date(),
      waktuKedatangan: new Date(),
      status: true,
    }, {
      id:"5",
      noMedRecord: '001/001/001-001',
      namaPasien: 'Jhon Lemon',
      jenisRawat: 'Rawat Jalan',
      unit: 'Klinik Umum',
      dokter: 'Eko Broto',
      tanggalKedatangan: new Date(),
      waktuKedatangan: new Date(),
      status: true,
    }
  ]

  const dataHeader = [{
    title: 'No',
    width: null,
  }, {
    title: 'No Medical Record',
    width: '200px',
  }, {
    title: 'Nama Pasien',
    width: '200px',
  }, {
    title: 'Jenis Rawat',
    width: '150px',
  }, {
    title: 'Unit / Pelayanan',
    width: '200px',
  }, {
    title: 'Dokter',
    width: '200px',
  }, {
    title: 'Tanggal Kedatangan',
    width: '200px',
  }, {
    title: 'Jam Kedatangan',
    width: '200px',
  }, {
    title: 'Check In',
    width: '100px',
  }]

  const [textFind, setTextFind] = useState('');

  const goToNavigateCheckIn = (value) => {
    navigate("/addcheckin",{state: value});
  }

  return (
    <>
      <Header />
      <Sidebar id='menu-item2' id1='menu-items2' activeClassName='appointment-list' />
      <div className="page-wrapper">
        <div className="content">

          <Breadcrumb parent={'Pasien '} child={'List Pasien'} />

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">

                <div className="card-header">
                  <div className="row">
                    <div className='col-12 col-md-6 col-xl-6' style={{ alignSelf: 'center', paddingBottom: '.5rem' }}>
                      <h4>List Pasien</h4>
                    </div>
                    <div className='col-12 col-md-6 col-xl-6'>
                      <div className="input-group mb-3" style={{}}>
                        <input type="text" className="form-control" placeholder="Masukan nama pasien atau nimir identitas" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setTextFind(e.target.value)}/>
                        <button className="btn btn-outline-secondary" type="button" id="button-addon2">Cari Pasien</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="table-responsive doctor-list">
                    <table className="table table-striped table-hover" style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          {
                            dataHeader.map((data, index) => {
                              return (<th key={index + 'header'} style={data.width ? { minWidth: data.width } : {}}>{data.title}</th>)
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          datasource.map((data, index) => {
                            let checkInclude = data.namaPasien.toLowerCase().includes(textFind.toLowerCase())
                            return (checkInclude ? <tr key={ index + 'body' }>
                              <th style={{ fontWeight: '400' }}> { index + 1 } </th>
                              <td> { data.noMedRecord } </td>
                              <td> { data.namaPasien } </td>
                              <td> { data.jenisRawat } </td>
                              <td> { data.unit } </td>
                              <td> { data.dokter } </td>
                              <td> { data.tanggalKedatangan.toISOString().split('T')[0] } </td>
                              <td> { data.waktuKedatangan.toISOString().split('T')[1].split('.')[0] } </td>
                              <td style={{ textAlign: 'center' }}>
                                <img src={icCheckIn} alt="#" style={{ width: '25px', cursor: 'pointer' }} onClick={() => goToNavigateCheckIn(data)} />
                              </td>
                            </tr> : null)
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AppointmentList;
