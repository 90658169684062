/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";

import TextInputBassic from '../subcomponents/textinputs/TextInputBassic'
import TextAreaInput from '../subcomponents/textinputs/TextAreaInput'
import DatePickerCust from '../subcomponents/textinputs/DatePicker'
import DropDown from '../subcomponents/textinputs/DropDown'
import Breadcrumb from '../subcomponents/headers/Breadcrumb'
import TitleForm from '../subcomponents/titles/TitleForm'
import CardWithTitle from '../subcomponents/card/CardWithTitle'

import { useLocation } from 'react-router-dom'; 

const AddCheckIn = (props) => {
  const data = props
  const location = useLocation();

  const [listKlink] = useState([{ 
    value: 1, 
    label: "Klinik Umum"
  }, { 
    value: 2, 
    label: "Klinik Anak"
  }, { 
    value: 3, 
    label: "Klinik Gigi"
  }])
  const [listPayment] = useState([{ 
    id: 1,
    type: 'Asuransi',
    subType: 'Prudensial',
    no: '8498283900000',
  }, { 
    id: 2,
    type: 'umum',
    subType: '',
    no: '-',
  }, { 
    id: 3,
    type: 'BPJS',
    subType: '',
    no: '000007768989',
  }])
  const [klinik, setKlinik] = useState('');
  const [firstDiagnose, setFirstDiagnose] = useState('');
  const [indexPayment, setIndexPayment] = useState('');

  return (
    <div>
      <Header />
      <Sidebar
        id="menu-item2"
        id1="menu-items2"
        activeClassName="add-check-in"
      />
      <>
        <div className="page-wrapper">
          <div className="content">
            {/* Page Header */}
            <Breadcrumb parent={'Pasien '} child={'Tambah Check In'} />
            {/* /Page Header */}

            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-header">
                    <h4>Check In</h4>
                  </div>

                  <div className="card-body">
                    {/* { JSON.stringify(location.state) } */}
                    <form>
                      <div className="row">
                        <div className="col-12" style={{ fontSize: '12px', fontWeight: 'bold', paddingBottom: '10px' }}>
                          <div className="form-heading">
                            <p style={{ marginBottom: '0px' }}>No Medical Record: <span className="login-danger" style={{ fontWeight: 'normal' }}> 001/001/001-001</span></p>
                            <p>No Apointment: <span className="login-danger" style={{ fontWeight: 'normal' }}> 003</span></p>
                          </div>
                        </div>

                        <TitleForm title={ 'Identitas Pasien: ' } spesific={ '*' } />

                        <CardWithTitle
                          label={'Nama Pasien'}
                          class={'col-12 col-md-6 col-xl-4'}
                          content={location.state?.namaPasien ? location.state?.namaPasien : '-'}
                          onClick={() => {}}
                        />

                        <CardWithTitle
                          label={'No Identitas ' + 'KTP'}
                          class={'col-12 col-md-6 col-xl-4'}
                          content={'087623459876123'}
                          onClick={() => {}}
                        />

                        <span style={{ fontSize: '.8rem', fontWeight: 'bold', color: 'darkblue', textAlign: 'right' }}>Data Pasien lengkap...</span>

                        <TitleForm title={ 'Informasi Payment Pasien: ' } spesific={ '*' } />
                        {
                          listPayment.map((data, index) => {
                            console.log()
                            return <CardWithTitle
                              key={index}
                              background={indexPayment === index.toString() ? 'rgba(132, 42, 42, 0.47)' : ''}
                              label={data.type}
                              labelRed={data.subType}
                              class={'col-12 col-md-6 col-xl-4'}
                              content={data.no}
                              type={true}
                              onClick={() => {setIndexPayment(index.toString())}}
                            />
                          })
                        }
                        
                        <TitleForm title={ 'Pelayanan: ' } spesific={ '* Pilih unit pelayanan dan diagnosa awal yg akan dikunjungi pasien' } />
                        <DropDown 
                          class={'col-12 col-md-6 col-xl-4'}
                          title={'Klinik'}
                          value={klinik}
                          change={setKlinik}
                          dataSelect={listKlink}/>

                        <TextAreaInput
                          label={'Diagnosa Awal'} 
                          value={firstDiagnose}
                          placeHolder={'-Demam \n- pusing \n- mules'} 
                          setValue={setFirstDiagnose} 
                          class={'col-12 col-md-6 col-xl-8'}
                        />

                      </div>
                    </form>
                  </div>

                  <div className="card-footer">
                    <div className="col-12">
                      <div className="doctor-submit text-end">
                        <button className="btn btn-primary" type="submit">Simpan</button>
                        <button className="btn btn-primary" style={{ background: 'transparent', border: 'solid 2px darkyellow', marginLeft: '20px' }} type="submit">Batalkan</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default AddCheckIn;
