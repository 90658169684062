import React from "react";
import Select from "react-select";

const DropDown = (props) => {
  const data = props

  return (
    <>
      <div className={data.class}>
      <div className="form-group local-forms">
        <label>
          { data.title } <span className="login-danger">*</span>
        </label>
        <Select
          menuPortalTarget={document.body}
          defaultValue={data.value}
          onChange={data.change}
          options={data.dataSelect}
          id="search-commodity"
          components={{
            IndicatorSeparator: () => null
          }}
          styles={{
            menuPortal: base => ({ ...base, zIndex: 9999 }),
            control: (baseStyles, state) => ({
              ...baseStyles,
              borderColor: state.isFocused ?'none' : '2px solid rgba(46, 55, 164, 0.1);',
              boxShadow: state.isFocused ? '0 0 0 1px #2e37a4' : 'none',
              '&:hover': {
                borderColor: state.isFocused ? 'none' : '2px solid rgba(46, 55, 164, 0.1)',
              },
              borderRadius: '10px',
              fontSize: "14px",
                minHeight: "45px",
            }),
            dropdownIndicator: (base, state) => ({
              ...base,
              transform: state.selectProps.menuIsOpen ? 'rotate(-180deg)' : 'rotate(0)',
              transition: '250ms',
              width: '35px',
              height: '35px',
            }),
          }}
        />

      </div>
    </div>
    </>
  )
}

export default DropDown;
// col-12 col-md-6 col-xl-4