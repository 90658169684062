import { GET_USER, TOOGLE_MENU } from './actions'

const initialState = {
    dataDummy: null,
    toogleMenu: true,
  };
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case GET_USER:
        return {
          ...state,
          dataDummy: action.payload
        };
      case TOOGLE_MENU:
        return {
          ...state,
          toogleMenu: action.payload
        };
      default:
        return state;
    }
  };
  