/* eslint-disable no-unused-vars */
import React,{ useState }  from 'react';
import { Table } from "antd";
import Header from '../Header';
import Sidebar from '../Sidebar';
import {onShowSizeChange,itemRender}from  '../Pagination'
import { blogimg10, imagesend, pdficon, pdficon3, pdficon4, plusicon, refreshicon, searchnormal, blogimg12,
     blogimg2, blogimg4, blogimg6, blogimg8, icCheckIn} from '../imagepath';
import { Link } from 'react-router-dom';
import Breadcrumb from '../subcomponents/headers/Breadcrumb'

const PatientsList = () => {
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const datasource = [
        {
          id:"1",
          firstName: 'Jhon',
          middleName: 'Michael',
          lastName: 'Doe',
          typeId: 'KTP',
          noId: '0123457895138',
          createAt: new Date(),
          updateAt: new Date(),
        },
        {
          id:"1",
          firstName: 'Jhon',
          middleName: 'Thor',
          lastName: 'Doe',
          typeId: 'KTP',
          noId: '0123457895335',
          createAt: new Date(),
          updateAt: new Date(),
        }
    ]

    const dataHeader = [{
      title: 'No',
      width: null,
    }, {
      title: 'Nama Pasien Lengkap',
      width: '200px',
    }, {
      title: 'Jenis Identitas',
      width: '150px',
    }, {
      title: 'No Identitas',
      width: '200px',
    }, {
      title: 'Tanggal pendaftaran',
      width: '200px',
    }, {
      title: 'Tanggal perbaruan',
      width: '200px',
    }, {
      title: 'Check In',
      width: '100px',
    }]

  const [textFind, setTextFind] = useState('');

  return (
    <>
      <Header />
      <Sidebar id='menu-item2' id1='menu-items2' activeClassName='patient-list' />
      <div className="page-wrapper">
        <div className="content">

          <Breadcrumb parent={'Pasien '} child={'List Pasien'} />

          <div className="row">
            <div className="col-sm-12">
              <div className="card card-table show-entire">

                <div className="card-header">
                  <div className="row">
                    <div className='col-12 col-md-5 col-xl-5' style={{ alignSelf: 'center', paddingBottom: '.5rem' }}>
                      <h4>List Pasien</h4>
                    </div>
                    <div className='col-12 col-md-5 col-xl-5'>
                      <div className="input-group mb-3" style={{}}>
                        <input type="text" className="form-control" placeholder="Masukan nama pasien atau nimir identitas" aria-label="Recipient's username" aria-describedby="button-addon2" onChange={(e) => setTextFind(e.target.value)}/>
                        <button className="btn btn-outline-secondary" type="button" id="button-addon2">Cari Pasien</button>
                      </div>
                    </div>
                    <div className='col-12 col-md-2 col-xl-2 add-group' style={{ alignSelf: 'start', paddingTop: '3px',   textAlign: 'center' , writingMode: 'vertical-rl'}}>
                      <Link
                        to="/addpatients"
                        className="btn btn-primary add-pluss ms-2"
                      >
                        <img src={plusicon} alt="#" />
                      </Link>
                    </div>
                  </div>
                </div>

                <div className="card-body">
                  <div className="table-responsive doctor-list">
                    <table className="table table-striped table-hover" style={{ width: '100%' }}>
                      <thead>
                        <tr>
                          {
                            dataHeader.map((data, index) => {
                              return (<th key={index + 'header'} style={data.width ? { minWidth: data.width } : {}}>{data.title}</th>)
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        {
                          datasource.map((data, index) => {
                            let fullName = `${data.firstName} ${data.middleName} ${data.lastName}`
                            let checkInclude = fullName.toLowerCase().includes(textFind.toLowerCase()) || data.noId.includes(textFind)
                            return (checkInclude ? <tr key={ index + 'body' }>
                              <th style={{ fontWeight: '400' }}> { index + 1 } </th>
                              <th style={{ fontWeight: '400' }}> { `${data.firstName} ${data.middleName} ${data.lastName}` } </th>
                              <td> { data.typeId } </td>
                              <td> { data.noId } </td>
                              <td> { data.createAt.toISOString().split('T')[0] } </td>
                              <td> { data.updateAt.toISOString().split('T')[0] } </td>
                              <td style={{ textAlign: 'center' }}>
                                <img src={icCheckIn} alt="#" style={{ width: '25px', cursor: 'pointer' }} />
                              </td>
                            </tr> : null)
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PatientsList;
