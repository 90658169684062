import React from "react";

const TextAreaInput = (props) => {
  const data = props

  return (
    <>
      <div className={data.class}>
        <div className="form-group local-forms">
            <label>
            { data.label } <span className="login-danger">*</span>
            </label>
            <textarea
            className="form-control"
            rows={3}
            cols={30}
            value={ data.value }
            placeholder={ data.placeHolder }
            onChange={(e) => data.setValue(e.target.value)}
            />
        </div>
      </div>
    </>
  )
}

export default TextAreaInput;
// col-12 col-md-6 col-xl-4