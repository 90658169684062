import React from "react";
import { DatePicker} from "antd";

const DatePickerCust = (props) => {
  const data = props

  return (
    <>
      <div className={data.class}>
        <div className="form-group local-forms cal-icon">
          <label>
            { data.title }
            <span className="login-danger">*</span>
          </label>
          <DatePicker
            value={ data.value }
            className="form-control datetimepicker"
            onChange={data.change}
            suffixIcon={null}
          />
        </div>
      </div>
    </>
  )
}

export default DatePickerCust;
// col-12 col-md-6 col-xl-4