import React from "react";

const CardWithTitle = (props) => {
  const data = props

  return (
    <>
      <div className={data.class} onClick={() => data.onClick()}>
        <div style={{ border: 'solid 1px lightgrey', borderRadius: '10px', padding: '10px', marginBottom: '20px', cursor: data.type === 'button' ? 'pointer' : 'unset', background: data.background }}>
          <label style={{ fontWeight: 'bold', fontSize: '1rem', paddingBottom: '10px' }}>
            { data.label } <span className="login-danger">* {data.labelRed}</span>
          </label>
          <p style={{ marginBottom: '0px' }}>{ data.content }</p>
        </div>
      </div>
    </>
  )
}

export default CardWithTitle;